/**
 * The purpose of this component is to allow sales to create reusuable templates for their preferences.
 * Note - it saves to _localstorage_. If it is lost, it is lost forever, but even an unreliable template that may need to be occasionally recreated is better than nothing.
 */

import { useAtom, Atom } from 'jotai';
import React, { useState, useEffect } from 'react';
import globalState from '../../../store';

// These are the properties we move from the global store to local storage.
// Starts with "custom_" and is followed by any characters.
const CUSTOM_PROPERTIES = /^custom_.+$/
const DYNAMIC_PROP_REGEX = [CUSTOM_PROPERTIES, /^use_custom_svg_background$/];

type ValueOf<T> = T extends Atom<infer V> ? V : never;
type GlobalState = ValueOf<typeof globalState>;
type Preset = Partial<GlobalState>;

// Gets the relvant keys from the global store and returns them as a preset object.
function getDynamicallyNamedKeyValues(obj: GlobalState): Preset {
  return Object.keys(obj)
    .filter((key) => DYNAMIC_PROP_REGEX.some((regex) => regex.test(key)))
    .reduce((acc, key) => {
      acc[key as keyof GlobalState] = obj[key as keyof GlobalState];
      return acc;
    }, {} as Preset);
}

export const CustomPresets = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);


  const getConfig = () => {
    const configObject = getDynamicallyNamedKeyValues(globalStateAtom);
    console.log("Getting config:", configObject);
    return configObject;
  };


  const loadConfig = (config: Preset) => {
    console.log('Loading config:', config);
    setGlobalState({...globalStateAtom, ...config});
  };

  // State for a new preset name (for "Save as Preset" or "Import Preset")
  const [presetName, setPresetName] = useState('');

  // State for the currently selected preset
  const [selectedPreset, setSelectedPreset] = useState('');

  // Object of all stored presets { [presetName]: configObject }
  const [presets, setPresets] = useState<Record<string, Preset>>({});

  useEffect(() => {
    const stored = localStorage.getItem('customTemplates');
    if (stored) {
      setPresets(JSON.parse(stored));
    }
  }, []);

  const updateLocalStorage = (newPresets: Record<string, Preset>) => {
    localStorage.setItem('customTemplates', JSON.stringify(newPresets));
  };

  const handleSaveAsPreset = () => {
    if (!presetName.trim()) {
      alert('Please enter a preset name.');
      return;
    }
    const currentConfig = getConfig();
    const newPresets = { ...presets, [presetName]: currentConfig };
    setPresets(newPresets);
    updateLocalStorage(newPresets);

    setSelectedPreset(presetName);
    setPresetName('');
  };

  const handleUpdatePreset = () => {
    if (!selectedPreset) {
      alert('No preset is selected to update.');
      return;
    }
    const currentConfig = getConfig();
    const newPresets = { ...presets, [selectedPreset]: currentConfig };
    setPresets(newPresets);
    updateLocalStorage(newPresets);

    alert(`Preset "${selectedPreset}" has been updated.`);
  };

  const handleLoadPreset = () => {
    if (!selectedPreset) {
      alert('Please select a preset to load.');
      return;
    }
    const selectedConfig = presets[selectedPreset];
    loadConfig(selectedConfig);
  };

  const handleDeletePreset = () => {
    if (!selectedPreset) {
      alert('No preset is selected to delete.');
      return;
    }
    // Omit selected preset from new object
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [selectedPreset]: _, ...remaining } = presets;
    setPresets(remaining);
    updateLocalStorage(remaining);

    alert(`Preset "${selectedPreset}" has been deleted.`);
    setSelectedPreset('');
  };

  const handleExportPreset = async () => {
    try {
      if (!selectedPreset) {
        alert('No preset selected to export.');
        return;
      }
      const configToExport = presets[selectedPreset];
      const jsonString = JSON.stringify(configToExport, null, 2);
      await navigator.clipboard.writeText(jsonString);

      alert(`Preset "${selectedPreset}" has been copied to clipboard.`);
    } catch (error) {
      console.error('Failed to export preset:', error);
      alert('Error exporting preset. Check console for details.');
    }
  };

  const handleImportPreset = async () => {
    try {
      if (!presetName.trim()) {
        alert('Please enter a preset name to import into.');
        return;
      }
      const textFromClipboard = await navigator.clipboard.readText();
      if (!textFromClipboard) {
        alert('Clipboard is empty or could not be read.');
        return;
      }

      const importedConfig = JSON.parse(textFromClipboard);

      if (typeof importedConfig !== 'object' || importedConfig === null) {
        throw new Error('Invalid preset data');
      }

      // Save this imported config under the name in presetName
      const newPresets = { ...presets, [presetName]: importedConfig };
      setPresets(newPresets);
      updateLocalStorage(newPresets);

      alert(`New preset "${presetName}" has been imported.`);
      setSelectedPreset(presetName);
      setPresetName('');
    } catch (error) {
      console.error('Failed to import preset:', error);
      alert('Error importing preset. Ensure clipboard contains valid JSON.');
    }
  };

  return (
    <div className="p-3 space-y-2">
      <p className='text-sm'>
        These presets are only saved on your browser. To move them to another device/browser, or to share with a colleague, use the export and import buttons. <br />
        <strong>Warning:</strong> These configurations are fragile. They may disappear, or become outdated as INT updates. The expectation if a preset is lost or broken is that it is recreated - not a bug.
      </p>
      <div>
        <input
          type="text"
          value={presetName}
          onChange={(e) => setPresetName(e.target.value)}
          placeholder="New preset name"
          className="border rounded px-2 py-1 mr-2"
        />
        <button
          onClick={handleSaveAsPreset}
          className="bg-blue-800 text-white p-1.5 rounded-lg mr-2"
        >
          Save as Preset
        </button>
        <button
          onClick={handleImportPreset}
          className="bg-blue-700 text-white p-1.5 rounded-lg"
        >
          Import
        </button>
      </div>

      <div>
        <select
          value={selectedPreset}
          onChange={(e) => setSelectedPreset(e.target.value)}
          className="border rounded px-2 py-1 mr-2"
        >
          <option value="" disabled>Select a saved preset</option>
          {Object.keys(presets).map((presetKey) => (
            <option key={presetKey} value={presetKey}>
              {presetKey}
            </option>
          ))}
        </select>

        <button
          onClick={handleLoadPreset}
          className="bg-green-600 text-white p-1.5 rounded-lg mr-2"
        >
          Load
        </button>
        <button
          onClick={handleUpdatePreset}
          className="bg-yellow-600 text-white p-1.5 rounded-lg mr-2"
        >
          Update
        </button>
        <button
          onClick={handleExportPreset}
          className="bg-blue-600 text-white p-1.5 rounded-lg mr-2"
        >
          Export
        </button>
        <button
          onClick={handleDeletePreset}
          className="bg-red-700 text-white p-1.5 rounded-lg"
        >
          Delete
        </button>
      </div>
    </div>
  );
};